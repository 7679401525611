.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
a hover, .nav-link:focus, .nav-link:hover{
  color:rgb(255, 0, 173)!important;
}
.advanced-cropper {
  max-height: 70vh!important;
}
@media (min-width: 768px) {
  .justify-content-end div.dropdown-menu.show{
    left: -220px!important;
  }
}
.carousel-item{
  width: 100%; /* La largeur prendra 100% de son parent */
  padding-top: calc(322 / 1200 * 100%); /* Hauteur proportionnelle au ratio 1200/500 */
  position: relative;
  overflow: hidden;
}
.carousel-item img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajuste l'image pour remplir l'espace en gardant le ratio */
}