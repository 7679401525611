@import "~react-image-gallery/styles/css/image-gallery.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.site-header {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  }
  .site-header a:hover { 
    text-decoration: none;
    }
    .site-header a { 
      transition: ease-in-out color .15s;
      }



      .container {
        max-width: 960px;
      }
      
      
      /*
       * Dummy devices (replace them with your own or something else entirely!)
       */
      
      .product-device {
        position: absolute;
        right: 10%;
        bottom: -30%;
        width: 300px;
        height: 540px;
        background-color: #333;
        border-radius: 21px;
        transform: rotate(30deg);
      }
      
      .product-device::before {
        position: absolute;
        top: 10%;
        right: 10px;
        bottom: 10%;
        left: 10px;
        content: "";
        background-color: rgba(255, 255, 255, .1);
        border-radius: 5px;
      }
      
      .product-device-2 {
        top: -25%;
        right: auto;
        bottom: 0;
        left: 5%;
        background-color: #e5e5e5;
      }
      
      
      /*
       * Extra utilities
       */
      
      .flex-equal > * {
        flex: 1;
      }
      @media (min-width: 768px) {
        .flex-md-equal > * {
          flex: 1;
        }
        .hide-768 {
          display:block;
        }
        .hm-768 {
          display:none;
        }
      }

      @media (max-width: 768px) {
      .site-header a {
        color:rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1)) !important;
      }
      .hide-768 {
        display:none;
      }
      .hm-768 {
        display:block;
      }
    }
      .nounderline{
        text-decoration: none;
      }

      .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      }




      .scrollable-div {
        scrollbar-width: none;
        width: 100%; /* Utilisez toute la largeur disponible */
        /* height: 200px; Définissez la hauteur souhaitée */
        overflow-x: auto; /* Activez le défilement horizontal */
        white-space: nowrap; /* Empêchez le retour à la ligne des images */
        padding-right: 30px;
      }
    
    .scrollable-div .hj {
        display: inline-block; /* Affichez les images en ligne pour qu'elles puissent être alignées horizontalement */
        height: 100%; /* Assurez-vous que les images prennent la hauteur complète de la div */
        max-width: 80%;
        margin:8px;/* Ajoutez un peu d'espace entre chaque image */
    }

    .scrollable-div::-webkit-scrollbar {
      display: none; /* Masquer les indicateurs de défilement */
  }
   
    